// search data to dataverse and open a new page with results
try {
    let searchDataverse = document.getElementById('searchDataverse')
    if (searchDataverse) {
        let dataDepositUrl = searchDataverse.dataset.depositUrl
        searchDataverse.addEventListener("submit", (e) => {
            e.preventDefault();
            let valueToSearch = e.currentTarget[0].value;
            window.open(dataDepositUrl + valueToSearch, '_blank');
        });
    }
}
catch(err) {
    console.warn('searchDataverse', err);
}

// scroll top of page
try {
    let target = document.getElementById("top");
    let backToTopBtn = document.getElementById("backtotop");
    let rootElement = document.documentElement;
    function callback(entries, observer) {
        entries.forEach(elem => {
            if (!elem.isIntersecting) {
                backToTopBtn.classList.add("showBtn");
            } else {
                backToTopBtn.classList.remove("showBtn");
            }
        });
    }
    function backToTop() {
        rootElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
    backToTopBtn.addEventListener("click", backToTop);
    let observer = new IntersectionObserver(callback);
    observer.observe(target);
}
catch(err) {
    console.warn('scroll top of page', err)
}

// Carroussel to Jeu de données
try {
    let containerDatagames = document.querySelector('.datagames-wrapper');
    if (containerDatagames) {
        let items2 = containerDatagames.querySelectorAll('.carousel .carousel-item');

        items2.forEach((el) => {
            const minPerSlide = 2;
            let next = el.nextElementSibling;
            for (var i=1; i<minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                    next = items2[0];
                }
                let cloneChild = next.cloneNode(true);
                el.appendChild(cloneChild.children[0]);
                next = next.nextElementSibling;
            }
        });
    }
}
catch(err) {
    console.warn('Carroussel Instituts', err)
}

// homepage change current language for cookies select (tarteaucitron.js)

let currentLanguage = document.documentElement.lang;
window.tarteaucitronForceLanguage = currentLanguage;

// contact page
// button reset
try {
    function resetForm() { 
        document.getElementById("contact_subject_email").value = "";
        document.getElementById("contact_subject_message").value = "";
        document.getElementById("contact_subject_subject").selectedIndex = 0;
    }
    let btnRsetForm = document.getElementById("btnresetform");
    if (btnRsetForm) {
        btnRsetForm.addEventListener("click", resetForm);
    }
}
catch(err) {
    console.warn('contact page | reset button', err)
}

// # 15/09/2022 - AJAX request to generate alerts disabled
// Replaced with a DOMContentLoaded Event:
window.addEventListener('DOMContentLoaded', (event) => {
    const alerts = document.querySelectorAll('.alert__message, .alert__message--text')

    if (alerts) {
        for (const alert of alerts) {
            alert.style.display = 'block'
        }
    }
})

/**
 * NAVBAR MENU HOVER
 * 
 */
;(function(){

    let hover = false

    const _init = () => {
        Array.from(document.querySelectorAll('.fr-nav__item')).forEach(elem=>{
            elem.addEventListener('mouseenter', _mouseenter)
            elem.addEventListener('mouseleave', _mouseleave)
        })
        reportWindowSize()
        window.onresize = reportWindowSize
    }

    const _mouseenter = (evt) => {
        evt.target.dataset.collapse = true
        deploy(evt.target)
    }

    const _mouseleave = (evt) => {
        evt.target.dataset.collapse = null
        evt.target.removeAttribute('data-collapse')
        collapse(evt.target)
    }

    const collapse = (elemMenu) => {
        if(hover && elemMenu.querySelector('.fr-nav__btn'))
            elemMenu.querySelector('.fr-nav__btn').setAttribute('aria-expanded', false)
    }

    const deploy = elemMenu => {
        if(hover && elemMenu.querySelector('.fr-nav__btn'))
            elemMenu.querySelector('.fr-nav__btn').setAttribute('aria-expanded', true)
    }

    const collapseAllDown = () => {
        Array.from(document.querySelectorAll('.fr-nav__item')).forEach(elem=>{
            try {
                if(elem.hasAttribute('data-collapse')) {
                    collapse(elem)
                }
                elem.removeAttribute('data-collapse')
            }
            catch(err){}
        })
    }

    function reportWindowSize() {
        let breakpointUp = window.innerWidth > 991
        if(breakpointUp!==hover && !breakpointUp) {
            collapseAllDown()
        }
        hover = breakpointUp
    }

    function ready(callback){
        // in case the document is already rendered
        if (document.readyState!='loading') callback();
        // modern browsers
        else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
        // IE <= 8
        else document.attachEvent('onreadystatechange', function(){
            if (document.readyState=='complete') callback();
        });
    }

    ready(_init)
})()

;(function(){
    let tmOutResize = null

    const _resize = () => {
        for (let key in window.POINTS) {
            const cardInteract = document.querySelector(`#id_map_${key}`),
                imgInteract = cardInteract.querySelector('img'),
                SIZE = imgInteract.getBoundingClientRect()
            if(!cardInteract) {
                console.warn('error', `card wit id id_map_${key} don't exists`)
                continue
            }
            cardInteract.style.setProperty('--width', SIZE.width)
            cardInteract.style.setProperty('--height',SIZE.height)
        }
    }

    const resizing = () => {
        if (tmOutResize) clearTimeout(tmOutResize)
        for (let key in window.POINTS) {
            const cardInteract = document.querySelector(`#id_map_${key}`)
            if(!cardInteract) {
                console.warn('error', `card wit id id_map_${key} don't exists`)
                continue
            }
            cardInteract.classList.add('hide-point')
        }
        tmOutResize = setTimeout(stopResize, 1000)
    }

    const stopResize = () => {
        for (let key in window.POINTS) {
            const cardInteract = document.querySelector(`#id_map_${key}`)
            if(!cardInteract) {
                console.warn('error', `card wit id id_map_${key} don't exists`)
                continue
            }
            cardInteract.classList.remove('hide-point')
        }
        _resize()
    }

    const linkMapClick = (el) => {
        el.preventDefault()
        try {
            const ref = JSON.parse(el.target.closest('.card-interact__point').dataset.ref)
            console.log(ref);
            if (ref.institution) {
                localStorage.setItem('hsh', ref.institution)
                return document.location.href=mapUtils.uri.institution
            }
            if(ref.dataworkshop) {
                localStorage.setItem('hsh', ref.dataworkshop)
                return document.location.href=mapUtils.uri.dataworkshop
            }
        }
        catch(err) {
            console.log('unidentified ref')
        }
    }

    const initLink = (el) => {
        el.addEventListener('click', linkMapClick)
    }

    const removeDNone = (id) => {
        const elem = document.querySelector(`#${id}`)
        if(elem?.classList.contains('card-interact__labels__none')) elem.classList.remove('card-interact__labels__none')
    }
     
    const _init = () => {
        _resize()
        for (let key in window.POINTS) {
            const cardInteract = document.querySelector(`#id_map_${key}`)
            window.POINTS[key].forEach(pointSelected => {

                if(pointSelected.institution) {
                    removeDNone(`id_labels_${key}`)
                    removeDNone(`id_labels_institution_${key}`)
                }
                if(pointSelected.dataworkshop) {
                    removeDNone(`id_labels_${key}`)
                    removeDNone(`id_label_datawokshop_${key}`)
                }

                const sClass = (pointSelected.institution)?' etablis':' atelier'
                cardInteract.insertAdjacentHTML('beforeend', `<div id="pi_${pointSelected.id}" class="card-interact__point${sClass}" style="--top:${pointSelected.point.y};--left:${pointSelected.point.x};" data-ref="${btoa(JSON.stringify(pointSelected))}"><div class="card-interact__point__tootltip"><div class="card-interact__point-title">${pointSelected.name}</div><a href="#" class="card-interact__selector">${mapUtils.translate.labelSeeLink}</a></div></div>`)
                const div = document.getElementById(`pi_${pointSelected.id}`)
            });
        }
        Array.from(document.querySelectorAll('.card-interact__selector')).forEach(el=>initLink(el))
        window.addEventListener('resize', resizing)
    }
    window.addEventListener('load', function () {
        _init()
    })
})()

// Remove underlined href <a> tag link if first child is <img> or if is Table of contents link lists
const aTags = document.querySelectorAll('a')

for (const aTag of aTags) {
    let contentsTableListTag = aTag.parentElement.parentElement
    let isContentsTableWidget = contentsTableListTag.parentNode.className.includes('widget-toc')
    if (aTag.hasChildNodes() && aTag.firstChild.tagName === 'IMG' || contentsTableListTag.tagName === 'OL' || isContentsTableWidget) {
        aTag.setAttribute('style', 'box-shadow:none')
    }
}

// Footer Contact Form Textarea Conditional field (Other request)
let contactForm = document.getElementById('contactform')
if (contactForm) {
    let choiceField = document.getElementById('contact_subject_subject')
    let conditionalField = document.querySelector('.conditional-field')
    conditionalField.style.display = 'none'

    choiceField.addEventListener('change', handleSelectChange)
    function handleSelectChange() {
        if (choiceField.value === 'Autre demande' || choiceField.value === 'Other request') {
            conditionalField.style.display = 'block'
        } else {
            conditionalField.style.display = 'none'
        }
    }
}

// Remove object comments in front office
let easyAdmin = document.querySelector('.ea')

if (!easyAdmin) {
    const comments = document.querySelectorAll('comment')
    const attributes = ['style', 'title', 'value', 'data-reply']

    for (const comment of comments) {
        for (const attribute of attributes) {
            comment.removeAttribute(attribute)
        }
    }
}

// Convert text DOI into links
function convertDoiToLinkInPage() {
    // Select page text
    var elementsWithText = document.querySelectorAll('div.fr-my-4w:not(input)');

    // DOI regex
    var regex = /\b(10\.[0-9]{4,}(?:\.[0-9]+)*\/(?:(?!["&'<>])\S)+)\b/g;

    // Replacing DOIs with links
    elementsWithText.forEach(function(element) {
        // Check if element is not an input 
        if (element.tagName.toLowerCase() !== 'input') {
            if (element.textContent.match(regex)) {
                element.innerHTML = element.innerHTML.replace(regex, '<a href="https://doi.org/$1" target="_blank">$1</a>');
            }
        }
    });
}
try {
    document.addEventListener('DOMContentLoaded', () => {
        convertDoiToLinkInPage();
    });
} catch (e) {
    console.log("no DOI to convert.")
}

// Replace Twitter link and logo by X social network 
document.addEventListener("DOMContentLoaded", function() {
    var oldLink = document.querySelector('a.fr-link--twitter.fr-link.hover-round-icon');

    if (oldLink) {
        var newLink = document.createElement('a');
        newLink.href = "https://twitter.com/recherchedatagv";
        newLink.target = "_blank";
        newLink.title = "Twitter - Nouvelle fenêtre";

        var svgLogo = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 24px; height: 24px;">
            <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
        </svg>`;

        newLink.innerHTML = svgLogo;
        oldLink.parentNode.replaceChild(newLink, oldLink);
    }
})

// Replace Linkedin logo
document.addEventListener("DOMContentLoaded", function() {
    var oldLink = document.querySelector('a.fr-link--page.linkedin.recherche.data.gouv.fr-link.hover-round-icon');

    if (oldLink) {
        var newLink = document.createElement('a');
        newLink.href = oldLink.href;
        newLink.target = oldLink.target;
        newLink.title = oldLink.title;

        var svgLogo = `<svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512 512" style="width: 24px; height: 24px;"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
            <path
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
        </svg>`;

        newLink.innerHTML = svgLogo;
        oldLink.parentNode.replaceChild(newLink, oldLink);
    }
})

// Replace Bluesky logo
document.addEventListener("DOMContentLoaded", function () {
    var oldLink = document.querySelector('a.fr-link--bluesky.fr-link.hover-round-icon');

    if (oldLink) {
        var newLink = document.createElement('a');
        newLink.href = oldLink.href;
        newLink.target = oldLink.target;
        newLink.title = oldLink.title;

        var svgLogo = `<svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512 512" style="width: 24px; height: 24px;"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
            <path
                d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z"/>
        </svg>`;

        newLink.innerHTML = svgLogo;
        oldLink.parentNode.replaceChild(newLink, oldLink);
    }
})

// Replace Mastodon logo
document.addEventListener("DOMContentLoaded", function() {
    var oldLink = document.querySelector('a.fr-link--mastodon.fr-link.hover-round-icon');

    if (oldLink) {
        var newLink = document.createElement('a');
        newLink.href = oldLink.href;
        newLink.target = oldLink.target;
        newLink.title = oldLink.title;

        var svgLogo = `<svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 448 512" style="width: 24px; height: 24px;"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
            <path
                d="M433 179.1c0-97.2-63.7-125.7-63.7-125.7-62.5-28.7-228.6-28.4-290.5 0 0 0-63.7 28.5-63.7 125.7 0 115.7-6.6 259.4 105.6 289.1 40.5 10.7 75.3 13 103.3 11.4 50.8-2.8 79.3-18.1 79.3-18.1l-1.7-36.9s-36.3 11.4-77.1 10.1c-40.4-1.4-83-4.4-89.6-54a102.5 102.5 0 0 1 -.9-13.9c85.6 20.9 158.7 9.1 178.8 6.7 56.1-6.7 105-41.3 111.2-72.9 9.8-49.8 9-121.5 9-121.5zm-75.1 125.2h-46.6v-114.2c0-49.7-64-51.6-64 6.9v62.5h-46.3V197c0-58.5-64-56.6-64-6.9v114.2H90.2c0-122.1-5.2-147.9 18.4-175 25.9-28.9 79.8-30.8 103.8 6.1l11.6 19.5 11.6-19.5c24.1-37.1 78.1-34.8 103.8-6.1 23.7 27.3 18.4 53 18.4 175z"/>
        </svg>`;

        newLink.innerHTML = svgLogo;
        oldLink.parentNode.replaceChild(newLink, oldLink);
    }
})